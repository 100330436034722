<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          登录记录
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <!--          <b-button variant="success" @click="edit()" class="mx-2">-->
          <!--            <b-icon icon="pencil"></b-icon>-->
          <!--            编辑-->
          <!--          </b-button>-->
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table v-model="table.selected" :headers="table.headers" :items="table.desserts"
            :single-select="table.singleSelect" :footer-props="table.footer" :server-items-length="table.itemsCount"
            :options.sync="table.pagination" :no-data-text="table.noDataText" :loading="table.loading"
            :loading-text="table.loadingText" item-key="customerId" show-select>

            <template v-slot:item.optType="{ item }">
              <h5>
                <b-badge variant="success" v-if="item.optType == 1">登录上线</b-badge>
                <b-badge variant="warning" v-else-if="item.optType == 2">退出登录</b-badge>
                <b-badge variant="danger" v-else-if="item.optType == 3">登录失败</b-badge>
              </h5>
            </template>

            <template v-slot:item.optTime="{ item }">
              <div>
                {{ item.optTime | formatDate }}
              </div>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.optTypeOptions" v-model="filter.optType"></b-form-select>
                </b-form-group>

                <b-form-group class="mr-1 ml-md-1 ml-lg-1 ml-xl-1 ml-sm-0 ml-0">
                  <b-form-datepicker locale="zh" v-model="filter.startDate" placeholder="起始日期"></b-form-datepicker>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">-</label>
                <b-form-group class="mx-2">
                  <b-form-datepicker locale="zh" v-model="filter.endDate" placeholder="结束日期"></b-form-datepicker>
                </b-form-group>

                <b-button variant="success" v-on:click="getData()">
                  <v-icon small>
                    flaticon-search
                  </v-icon>
                  查询
                </b-button>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "Logs",
  components: {
    KTCard,
  },
  props: {
    title: String
  },
  data() {
    return {
      filter: {
        conditions: [
          { value: null, text: '检索条件' },
          { value: "loginAccount", text: '登录账号' },
          { value: "realName", text: '真实姓名' },
          { value: "ipAddress", text: 'IP地址' },
        ],
        optTypeOptions: [
          { value: null, text: '操作类型' },
          { value: 1, text: '登录上线' },
          { value: 2, text: '退出登录' },
          { value: 3, text: '登录失败' },
        ],
        optType: null,
        field: null,
        keyword: ""
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [
          { text: "操作类型", value: "optType" },
          {
            text: "登录账号",
            align: "left",
            sortable: false,
            value: "loginAccount"
          },
          { text: "真实姓名", value: "realName" },
          { text: "IP地址", value: "ipAddress" },
          { text: "模块名称", value: "moduleName" },
          { text: "附加信息", value: "options" },
          { text: "操作时间", value: "optTime" },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true
        },
        pagination: {},
        desserts: []
      },
    };
  },
  mounted() {

  },
  created() {

  },
  methods: {
    getData() {
      let startTime = this.filter.startDate, endTime = this.filter.startDate;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }
      let params = {
        "pageNum": this.table.pagination.page,
        "pageSize": this.table.pagination.itemsPerPage,
        "optType": this.filter.optType,
        "startDate": startTime,
        "endDate": endTime
      };
      params["" + this.filter.field + ""] = this.filter.keyword;
      ApiService.query("/api/logs/query_list", {
        params: params
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      })
    },

    getSelectedIds() {
      let ids = new Array();
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.customerId);
        })
      }
      return ids;
    }
  },
  computed: {
    pagination: function () {
      return this.table.pagination
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getData()
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
